import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

//Bike
export default function form(content, nextState, vrs) {// content div della view
    const {context, requestData, assetType} = vrs.session;

    var modal = $(content).closest('.modal');
    var errorMsgEl = $(".user-msg", content).hide();
    var form = $("form", content);// form ricerca

    var startDate = $("[name=startDate]", content);
    var endDate = $("[name=endDate]", content);

    $(startDate).datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: "L",
        minDate: new Date(),
        onSelect: function (date) {
            Utils.updateBookingTimeRestriction(startDate, endDate, assetType, true);
        }
    });

    $(endDate).datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: "L",
        minDate: new Date()
    });

    Utils.updateBookingTimeRestriction(startDate, endDate, assetType);

    $(form).off().on("submit", function (e) {
        e.preventDefault();

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var xhr = $.post(task("Booking.searchBike"), Utils.serializeForm(form), function (data) {

            if (!data.success) {// Errore durante la ricerca
                $(errorMsgEl).html(data.message).show();
                return;
            }

            vrs.goToView("solutions", {
                html: data.html
            });

        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore durante la ricerca"), 'error', status);
            }
        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    $(content).on("change", "[name=departureParkingAreaId]", function () {
        if ($('[name=destinationParkingAreaId]', form).length) {
            Utils.setSelectionIfValExist($('[name=destinationParkingAreaId]', form), $(this).val());
        }
    });

    return function () {
        $(startDate).datepicker("destroy");
        $(endDate).datepicker("destroy");
    }
};
