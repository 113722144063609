import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";

export default function registerLocation(form) {

    $(".transport-location-form", form).each(function (index) {
        var xhrHereGc = null;
        var locationLoader = $(".location-loading-overlay", this);

        var country = $('.tr-country', this);
        var city = $('.tr-city', this);
        var address = $('.tr-address', this);
        var number = $('.tr-number', this);
        var zipCode = $('.tr-zipCode', this);

        var positionLat = $('.tr-lat', this);
        var positionLng = $('.tr-lng', this);

        var addressAlert = $(".cp-msg-address-alert", this);

        function buildHereQQ(obj) {
            var list = [];
            for (var k in obj) {
                if (obj.hasOwnProperty(k)) {
                    if (obj[k] !== "") {
                        obj[k] = obj[k].replace("=", '');
                        obj[k] = obj[k].replace(";", '');
                        list.push(k + "=" + obj[k]);
                    }
                }
            }
            return list.join(';');
        }

        $(country).change(() => {
            $(addressAlert).hide();
            $('input', this).val("");
        });

        $(this).on('blur', 'input:not(not-for-blur)', function () {

            var isoCountry = $(country).val();
            if (isoCountry.trim() === "") {
                sa2Alert(__t("Seleziona la nazione"));
                return;
            }

            if ($(city).val().trim() === "" && $(zipCode).val().trim() !== "") {
                // Evita forzatura città da cap
                return;
            }

            var requestGeocode = {
                apiKey: cpAppSet.leaflet.here.apiKey,
                lang: "it-IT",
                in: "countryCode:" + isoCountry,
                qq: buildHereQQ({
                    country: isoCountry, // Uno deve essere presente per forza
                    city: $(city).val(),
                    street: $(address).val(),
                    houseNumber: $(number).val(),
                    postalCode: $(zipCode).val(),
                }),
                limit: 2
            };

            if (xhrHereGc !== null) {
                xhrHereGc.abort();
            }

            $(locationLoader).show();

            xhrHereGc = $.getJSON("https://geocode.search.hereapi.com/v1/geocode", requestGeocode)
                    .done(function (responseGeocode) {

                        $(positionLat).val("");
                        $(positionLng).val("");

                        if (responseGeocode.hasOwnProperty("items") && responseGeocode.items.length) {

                            var itemGeocode = responseGeocode.items[0];
                            var addr = itemGeocode.address;

                            $(addressAlert).hide();

                            $(city).val((addr.city) ? addr.city : "");

                            if ($(city).val() && $(address).val() && !addr.hasOwnProperty("street")) {
                                $(addressAlert).show();
                            } else {
                                $(address).val((addr.street) ? addr.street : "");
                            }

                            $(zipCode).val((addr.postalCode) ? addr.postalCode : "");

                            if (itemGeocode.position) {
                                $(positionLat).val(itemGeocode.position.lat);
                                $(positionLng).val(itemGeocode.position.lng);
                            }

                        } else {
                            $(addressAlert).show();
                        }

                        $(locationLoader).hide();
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        $(locationLoader).hide();
                    });

        });

    });

}




