/*
 * This file is part of the CloserPHP package.
 * For the full copyright and license information, please view the LICENSE.php
 * file that was distributed with this source code.
 * Info:andread.dev@gmail.com
 */

export default function task(task, param) {
    task = typeof task !== 'undefined' ? task : "";
    param = typeof param !== 'undefined' ? param : "";

    task = task
            .replace("\\", "/")
            .replace(/([A-Z])/g, "-$1")
            .replace(/\.\-/g, '.')
            .toLowerCase()
            .replace(/\-+/g, '-')
            .replace(/\-\/|\/\-/g, '/')
            .replace(/^-+|-+$/g, '');

    param = "t=" + task + ((param !== "") ? "&" : "") + param;

    if (cpAppSet.currentLang !== cpAppSet.defaultLang) {
        param = param + ((param !== "") ? "&" : "") + "l=" + cpAppSet.currentLang;
    }
    return cpAppSet.baseEntryUrl + "?" + param;
}

