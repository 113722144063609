import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

export default function addDestination(content, nextState, vrs) { // content div della view
    const {context, requestData, assetType} = vrs.session;

    var modal = $(content).closest('.modal');
    var errorMsgEl = $(".user-msg", content).hide();//Cross views
    var form = $(".view-form form", modal);// form ricerca

    var dnSaveBtn = $(".btn-save-destination", content);
    var dnSearchName = $("input[name=name]", content);
    var dnSearchPos = $("input[name=position]", content);

    $(dnSearchName).val("");
    $(dnSearchPos).val("");
    $(dnSaveBtn).prop("disabled", true);

    //Autocomplete ricerca HERE
    var destinationXhr = null;
    $(dnSearchName).autocomplete({
        dataType: "json",
        minLength: 2,
        appendTo: content,
        source: function (request, response) {

            var term = $.trim(request.term);
            if (term.length === 0) {
                return;
            }

            Utils.abortXhr(destinationXhr);

            destinationXhr = $.ajax({
                //url: "//geocoder.api.here.com/6.2/geocode.json",
                url: "https://geocode.search.hereapi.com/v1/geocode",
                dataType: "json",
                headers: [], // Remove default.js cpAppSet.csrfToken.name
                data: {
                    apiKey: cpAppSet.leaflet.here.apiKey,
                    /*app_id: cpAppSet.leaflet.here.appId,
                     app_code: cpAppSet.leaflet.here.appCode,*/
                    languages: cpAppSet.currentLocale,
                    //in: "countryCode:" + "ITA",
                    //country: "ITA", // Solo per veritas
                    q: term,
                    //searchtext: term,                    
                    maxresults: 20
                }
            }).done(function (data) {
                var suggestionsData = [];

                if (data && data.items && data.items.length) {
                    suggestionsData = data.items;
                    suggestionsData = suggestionsData.filter(function (el) {
                        return ($.inArray(el.resultType, ["street", "intersection", "houseNumber"]) > -1);
                    }).slice(0, 10);//limit result;
                }
                response($.map(suggestionsData, function (value, key) {
                    return{
                        label: value.address.label,
                        value: value.position.lat + "|" + value.position.lng
                    };
                }));
            });
        },
        select: function (e, ui) {//Selezione elemento dalla lista
            e.preventDefault();
            $(dnSearchName).val(ui.item.label);
            $(dnSearchPos).val(ui.item.value);
            $(dnSaveBtn).prop("disabled", false);
        },
        focus: function (e, ui) {
            e.preventDefault();
            $(dnSearchName).val(ui.item.label);
            $(dnSearchPos).val(ui.item.value);
        },
        change: function (e, ui) {
            if (ui.item == null || ui.item == undefined) {
                $(dnSearchName).val("");
                $(dnSearchPos).val("");
                $(dnSaveBtn).prop("disabled", true);
            }
        },
        position: {
            collision: "flip"
        }
    });

    // Submit form
    $(content).on("submit", "form", function (e) {
        e.preventDefault();

        if ($(dnSaveBtn).is(':disabled')) {
            //console.log("Prevent submit");
            return false;
        }

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var pos = ($(dnSearchPos).val()).split("|");
        var data = {
            name: $(dnSearchName).val(),
            lat: pos[0],
            lng: pos[1]
        };

        var xhr = $.post(task("Booking.addDestination"), data, function (data) {

            if (!data.success) {
                $(errorMsgEl).text(data.message).show();
                //sa2Toast(data.message, 'error');
                return;
            }

            var intSelection = $('[name=intermediateDestinationId]', form);

            $(intSelection).append(
                    $("<option></option>").attr("value", data.current.id).text(data.current.name)
                    ).val(data.current.id);

            vrs.goToView("form", {});

        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore di comunicazione con il server"), 'error', status);
            }
        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    return function () {
        Utils.abortXhr(destinationXhr);
        $(dnSearchName).autocomplete("destroy");
    }
};
