import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import useFormUtils from "@commons/useFormUtils";

export default function (options = {}){
    options = $.extend({}, {onSuccess: function () {}}, options);

    var baseTask = cpAppSet.currentTask.class;

    var modal = $("#reject-request-md");
    if (!$(modal).length) {
        console.log("Missing #reject-request-md");
        return;
    }

    var form = $("form", modal);
    var FormUtils = useFormUtils(form);
    var loader = $(".loader-overlay", modal);
    var xhrPost = null;// verifica campi


    $(modal).on('show.bs.modal', function (e) {
        var invokerData = $(e.relatedTarget).data();
        console.log(invokerData);

        FormUtils.field("id").val(invokerData.id);

        $(form).on('submit', function (e) {
            e.preventDefault();

            if (xhrPost) {
                xhrPost.abort();
            }

            $(loader).show();
            FormUtils.hideMessage();
            FormUtils.clearErrors();

            xhrPost = $.post(task(baseTask + ".reject"), $(form).serialize(), function (response) {

                if (!response.success) {

                    FormUtils.showMessage(response.message);

                    if (response.hasOwnProperty('errorFields')) {
                        FormUtils.showErrors(response.errorFields);
                    }
                    return;
                }

                options.onSuccess(response);
                $(modal).modal('hide');

            }, 'json').done(function () {
            }).fail(function (jqxhr, textStatus, error) {
            }).always(function () {
                $(loader).hide();
            });

        });

    });

    $(modal).on('hidden.bs.modal', function (e) {
        $(form).off();

        if (xhrPost) {
            xhrPost.abort();
        }

        FormUtils.hideMessage();
        FormUtils.clearErrors();

        $(form)[0].reset();
        FormUtils.field("id").val("");
    });

}


