import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

//EScooter
export default function thx(content, nextState, vrs) {
    const {context, requestData, assetType} = vrs.session;

    var modal = $(content).closest('.modal');
    var errorMsgEl = $(".user-msg", content).hide();

    console.log(nextState);
    if (nextState.details) {
        $(".reponse-dettails", content).text(nextState.details);
    }

    return function () {
    }
};
