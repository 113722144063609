import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import RemoteView from "./Utils/RemoteView";

$.fn.modal.Constructor.prototype._enforceFocus = function () {};//fix focus bootstrap modal

// Handlers
import car from "./Entries/Car/car";
import bike from "./Entries/Bike/bike";
import escooter from "./Entries/EScooter/escooter";
import shuttleRide from "./Entries/ShuttleRide/shuttleRide"

export default function registerBookingModal(options = {}) {

    options = $.extend({}, {onModalClose: function () {}}, options);

    var modal = $("#solutions-wrap");

    var initActions = {
        onFail: function (context, jqxhr) {// TODO anche onError
            if (jqxhr.status === 401) {
                setTimeout(function () {
                    sa2Alert(__t("Sessione scaduta esegui il login"), "warning", function () {
                        window.location.href = cpAppSet.baseEntryUrl;
                    });
                }, 100);
            } else {
                sa2Toast(__t("Errore di comunicazione con il server"), 'error');
            }

            setTimeout(function () {// Non è del tutto caricata su show.bs.modal, aspetto 
                $(modal).modal('hide');
            }, 100);

        }
    };
    var remoteView = new RemoteView($(".rv-content", modal), initActions);

    $(modal).on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget); //bookingId and assetType (decide onLodad, e view)
        var assetType = $(invoker).data("assetType");
        var bookingId = $(invoker).data("bookingId");

        var modaTitle = $("#modal-asset-title").empty();

        switch (assetType.toLowerCase()) {
            case "car":
                $(modaTitle).text((bookingId) ? __t("Modifica prenotazione auto") : __t("Nuova prenotazione auto"));
                //remoteView.load(task("Booking.xhr", "view=carForm"), $(invoker).data(), {onLoad: carHandler()});
                remoteView.load(task("Booking.xhr", "view=carForm"), $(invoker).data(), {onLoad: car});
                break;
            case "bike":
                $(modaTitle).text((bookingId) ? __t("Modifica prenotazione bici") : __t("Nuova prenotazione bici"));
                remoteView.load(task("Booking.xhr", "view=bikeForm"), $(invoker).data(), {onLoad: bike});
                break;
            case "escooter":
                $(modaTitle).text((bookingId) ? __t("Modifica prenotazione eScooter") : __t("Nuova prenotazione eScooter"));
                remoteView.load(task("Booking.xhr", "view=escooterForm"), $(invoker).data(), {onLoad: escooter});
                break;
            case "shuttleride":
                $(modaTitle).text((bookingId) ? __t("Modifica prenotazione navetta") : __t("Nuova prenotazione navetta"));
                remoteView.load(task("Booking.xhr", "view=shuttleRideForm"), $(invoker).data(), {onLoad: shuttleRide});
                break;
            default:
                console.log("Invalid assetType");
                break;
        }
    });

    $(modal).on('hidden.bs.modal', function (e) {
        remoteView.destroy();
        options.onModalClose();
    });

    if ($("#requestPLId").length) {
        $("#requestPLId").trigger("click");
    }
    ;

};
