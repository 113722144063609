import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";

import ViewsRouteSwitcher from "../../Utils/ViewsRouteSwitcher";
import Utils from "../../Utils/Utils";

import form from "./Handlers/form";
import solutions from "./Handlers/solutions";

export default function escooterEntry(container, context, response, requestData, fnStatus) {
    //container= $(".rv-content", modal)

    var router = new ViewsRouteSwitcher($(".views", container), {
        context: context,
        requestData: requestData,
        assetType: "ESCOOTER",
        fnStatus: fnStatus,
        response: response,
    });

    router.registerViewHandler(form, "form");
    router.registerViewHandler(solutions, "solutions");

    router.goToView("form");

    return function () {
        router.destroy();
    };

};

