/*
 * This file is part of the CloserPHP package.
 * For the full copyright and license information, please view the LICENSE.php
 * file that was distributed with this source code.
 * Info:andread.dev@gmail.com
 */

class RemoteView {

    constructor(container, actions = {}, parentContext = null) {
        this.container = container;

        this.actions = $.extend({}, {
            onInit: (context) => {
            },
            beforeLoad: (context) => {
            },
            onLoad: (container, context, response, requestData, fnStatus) => {
            },
            onError: (context, responseData) => {
            },
            onFail: (context, jqxhr) => {
            },
            onComplete: (context) => {
            }
        }, actions ? actions : {});

        this.xhr = new Set();
        this.destroyCall = null;
        this.childs = new Set();
        this.state = {};
        this.loading = false;
        this.fnSatatus = {mounted: false};

        this.eventHandlers = new Map();

        this.parentContext = parentContext;
        if (parentContext) {
            this.rootContext = parentContext.rootContext;
            parentContext.childs.add(this);
        } else {
            this.rootContext = this;
        }

        this.targetClassEl = ($(container).parent().hasClass('rv-container')) ?
                $(container).parents('.rv-container') : this.container;

        this.ajaxSettings = {};
        this.actions.onInit(this);
    }

    destroy = () => {

        this.xhr.forEach((xhr) => {
            xhr.abort();
        });
        this.xhr.clear();

        this.childs.forEach((context) => {
            context.destroy();
        });
        this.childs.clear();

        if (this.destroyCall) {
            this.destroyCall();
        }
        this.destroyCall = null;

        this.eventHandlers.clear();

        this.parentContext = null;
        this.rootContext = null;

        this.state = {};

        $(this.targetClassEl).removeClass("rv-fail");

        this.setLoading(false);

        this.fnSatatus.mounted = false;
        this.fnSatatus = {mounted: false};

        $(this.container).empty();
    }

    setAjaxDefaults = (settings) => {
        this.ajaxSettings = settings;
        return this;
    }

    setLoading = (isLoading) => {
        this.loading = isLoading;
        $(this.targetClassEl).toggleClass("rv-loading", isLoading);
        return this;
    }

    load = (url = null, data = {}, actions = {}, ajaxSettings = {}) => {
        this.loading = true;

        actions = $.extend({}, this.actions, actions);//Override default actions

        ajaxSettings = $.extend({}, {
            type: "POST",
            url: url,
            data: data,
            dataType: "json"
        }, this.ajaxSettings, ajaxSettings);

        this.xhr.forEach((xhr) => {
            xhr.abort();
        });
        this.xhr.clear();

        actions.beforeLoad(this, ajaxSettings);

        this.setLoading(true);

        var xhr = $.ajax(ajaxSettings)
                .done((response) => {

                    $(this.targetClassEl).removeClass("rv-fail");

                    if (response.success) {

                        this.childs.forEach((context) => {
                            context.destroy();
                        });
                        this.childs.clear();

                        if (this.destroyCall) {
                            this.destroyCall();
                        }
                        this.destroyCall = null;

                        this.eventHandlers.clear();

                        $(this.container).html(response.html);

                        this.setLoading(false);
                        this.fnSatatus.mounted = false;
                        this.fnSatatus = {mounted: true};

                        this.destroyCall = actions.onLoad(
                                this.container,
                                this,
                                response,
                                data,
                                this.fnSatatus
                                );

                        if (typeof this.destroyCall !== 'function') {
                            if (this.destroyCall) {
                                console.warn("RemoteView: bad return type from onLoad function");
                            }
                            this.destroyCall = null;
                        }

                    } else {
                        this.setLoading(false);
                        actions.onError(this, response);
                    }

                    actions.onComplete(this, response);

                }).fail((jqxhr, textStatus, error) => {

            this.setLoading(false);
            if (textStatus !== "abort") {
                $(this.targetClassEl).addClass("rv-fail");
                actions.onFail(this, jqxhr);
            }

            actions.onComplete(this);
        });

        this.xhr.add(xhr);
        return this;
    }

    on = (type, handler) => {
        if (!this.eventHandlers.has(type)) {
            this.eventHandlers.set(type, new Map());
        }
        this.eventHandlers.get(type).set(handler, handler);
    }

    off = (type, handler) => {
        if (this.eventHandlers.has(type) && this.eventHandlers.get(type).has(handler)) {
            this.eventHandlers.get(type).delete(handler);
        } else {
            console.log("RemoteView: missing off" + type);
        }
    }

    emit = (type, data, deep = true) => {
        if (this.eventHandlers.has(type)) {
            this.eventHandlers.get(type).forEach((handler, key) => {
                handler(data);
            });
        }

        if (deep) {
            this.childs.forEach((context) => {
                context.emit(data, deep);
            });
        }
        ;
    }
}

export default RemoteView;