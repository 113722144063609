import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";

class Utils {

    static serializeForm(form, merge) {
        var formData = {};

        var formDataObj = $(form).serializeJSON();
        var formData = Object.keys(formDataObj).reduce(function (object, key) {
            var element = $('[name=' + key + ']', form);
            if (!$(element).hasClass("exclude")) {
                object[key] = formDataObj[key];
            }
            return object;
        }, {});

        if (merge) {
            formData = $.extend({}, formData, merge);
        }
        return formData;
    }

    static abortXhr(xhr) {
        if (xhr) {
            xhr.abort();
        }
    }

    static escapeHtml(string) {
        var entityMap = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '/': '&#x2F;',
            '`': '&#x60;',
            '=': '&#x3D;'
        };

        return String(string).replace(/[&<>"'`=\/]/g, function fromEntityMap(s) {
            return entityMap[s];
        });
    }

    static replacePlaceholder(string, data) {
        return string.replace(/\{\{(\w+)\}\}/g, function (match, keyPl) {
            return data[keyPl] || match;
        });
    }

    static setSelectionIfValExist(select, val) {
        if ($("option[value='" + val + "']", select).length > 0) {
            $(select).val(val);
        } else {
            $("option:first", select).attr('selected', 'selected');
        }
    }

    static registerNumberSpinner(target) {
        $(".number-spinner", target).each(function () {

            if ($(this).hasClass("hasSpinner")) {
                return;
            }

            $(this).addClass("hasSpinner");
            var input = $(this).find('input');
            $(".btn", $(this)).click(function () {
                var oldValue = input.val().trim();
                if ($(this).attr('data-type') === 'plus') {
                    if (oldValue < input.attr('max')) {
                        oldValue++;
                    }
                } else {
                    if (oldValue > input.attr('min')) {
                        oldValue--;
                    }
                }
                input.val(oldValue);
            });

        });
    }

    // Solo per car
    static describeAviabilityFromTo(departure, instance = null, criteria = null) {
        var title = `<h4>
            ${__t("Deposito %s", null, Utils.escapeHtml(departure.name))}<br>
            <small>${Utils.escapeHtml(departure.address)}, ${departure.number}</small>
        </h4>`;

        if (instance) {
            var itemAsset = (instance && instance.assetData) ? Utils.escapeHtml(instance.assetData.model) + " (" + instance.assetData.plate + ")" : "";
            var itemCategory = (instance && instance.categoryData) ? Utils.escapeHtml(instance.categoryData.description) : "";

            if (criteria && criteria.hasOwnProperty("tripFeatures") && criteria.tripFeatures.tripMode === "PRIVATE") {
                title += `<h5 class='mt-3'>
                    ${__t("Modello: <b>%s</b>", null, itemAsset)}<br>
                </h5>`;
            } else {
                title += `<h5 class='mt-3'>
                    ${__t("Tipologia: <b>%s</b>", null, itemCategory)}<br>
                </h5>`;
            }

            var starDateStr = moment(instance.startDate).format("L HH:mm");
            var endDateStr = moment(instance.endDate).format("L HH:mm");

            title += `<h6>
                ${__t("Disponibile dal <b>%s</b> al <b>%s</b>", null, starDateStr, endDateStr)}<br>
            </h6>`;
        }

        return title;
    }

    static updateBookingTimeRestriction(startDateEl, endDateEl, assetType, onChange = false) {
        if (!$(endDateEl).hasClass("hasDatepicker") || !$(endDateEl).hasClass("hasDatepicker")) {
            console.log("Missin ui datepiker on updateBookingTimeRestriction");
            return;
        }

        var end = moment($(endDateEl).datepicker('getDate'));
        var start = moment($(startDateEl).datepicker('getDate'));

        var timePolicy = $(startDateEl).data("timePolicy");

        //timePolicy = "BY_NEXT_FRIDAY";

        if (start.isValid()) {// Start date valida

            var maxDate = null;
            var minDate = null;
            var currentDate = start;
            var dayOfWeek = parseInt(currentDate.isoWeekday());// 1 being Monday and 7 being Sunday

            switch (timePolicy) {
                case "BY_SAME_DAY": //Consegna entro il giorno di inizio prenotazione
                    maxDate = minDate = currentDate;
                    break;
                case "BY_NEXT_DAY": //Consegna entro il giorno successivo all’inizio prenotazione.
                    maxDate = currentDate.clone().add(1, 'days');
                    minDate = currentDate;
                    break;
                case "BY_NEXT_FRIDAY": //Consegna entro la settimana della prenotazione (il venerdi)                   
                    var dayDistance = Math.abs(dayOfWeek - 5);
                    var endOfWeek = null;

                    if (dayOfWeek > 5) {
                        endOfWeek = currentDate.clone().add('days', 7 - dayDistance);
                    } else {
                        endOfWeek = currentDate.clone().add('days', dayDistance);
                    }

                    minDate = currentDate;
                    maxDate = endOfWeek;
                    break;
                case "BY_NEXT_SUNDAY":
                    var dayDistance = Math.abs(dayOfWeek - 7);
                    var endOfWeek = currentDate.clone().add('days', dayDistance);

                    minDate = currentDate;
                    maxDate = endOfWeek;
                    break;
                default:
                    //Nessuna azione
                    if (timePolicy) {
                        console.log("Missing timePolicy restriction:" + timePolicy);
                    }
                    break;
            }

            if (minDate && minDate.isValid() && maxDate && maxDate.isValid()) {

                if (end.isValid()) {
                    if (end.toDate() > maxDate.toDate() || end.toDate() < minDate.toDate()) {
                        $(endDateEl).val(minDate.format("L"));
                    }
                } else {
                    $(endDateEl).val(minDate.format("L"));
                }

                $(endDateEl).datepicker("option", {minDate: minDate.toDate(), maxDate: maxDate.toDate()});

            } else {
                if (!end.isValid() || end.toDate() < start.toDate()) {
                    $(endDateEl).val(start.format("L"));
                }

                $(endDateEl).datepicker("option", {minDate: new Date(), maxDate: null});
            }
        }
        ;
    }
}

export default Utils;

