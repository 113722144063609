import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

export default function promotions(content, nextState, vrs) { // content div della view
    const {context, requestData, assetType} = vrs.session;

    //nextState.solutionsHtml
    $(".promotions-content", content).html(nextState.html);

    $(content).on("click", ".btn-accept-promotions", function (e) {
        e.preventDefault();

        var promotionItemEl = $(this).closest(".promotion-item");
        var title = __t("Hai scelto") + ": " + $(promotionItemEl).find(".title").text() + " (" + $(promotionItemEl).find(".title-amount").text() + ")";

        vrs.goToView("solutions", {
            html: nextState.solutionsHtml,
            promotion: {
                title: title,
                promotionId: $(this).data("id"),
                index: $(this).data("index"),
            }
        });
    });

    $(content).on("click", ".btn-discard-promotions", function (e) {
        e.preventDefault();
        vrs.goToView("solutions", {
            html: nextState.solutionsHtml,
        });
    });

    return function () {
    }

}
