/*
 * This file is part of the CloserPHP package.
 * For the full copyright and license information, please view the LICENSE.php
 * file that was distributed with this source code.
 * Info:andread.dev@gmail.com
 */

export function __t(string, domain) {
    //return window.cpI18n.__t(string, domain);
    return window.cpI18n.__t.apply(null, arguments);
}

