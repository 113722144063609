import {__t} from "@commons/i18nRef";

export function createLayout(msg, icon) {

    var iconClass = 'fa-info-circle text-primary';

    switch (icon) {
        case "success":
            iconClass = 'fa-check-circle text-success';
            break;
        case "error":
            iconClass = 'fa-exclamation-circle text-danger';
            break;
        case "warning":
            iconClass = 'fa-exclamation-triangle text-warning';
            break;
        case "question":
            iconClass = 'fa-question-circle text-primary';
            break;
        default:
    }

    return '<div class="text-cener my-3 d-block">' + '<i class="fas ' + iconClass + ' fa-3x"></i>' + '</div>' + '<div class="d-block mb-2">' + msg + "</div>";
}


export const swalWithBootstrapButtons = Swal.mixin({
    title: null,
    width: "auto",
    icon: null,
    customClass: {
        confirmButton: 'btn btn-primary btn-sm',
        cancelButton: 'btn btn-secondary btn-sm'
    },
    buttonsStyling: false
});

export function sa2Confirm(msg, callback, icon = "question") {
    swalWithBootstrapButtons.fire({
        html: createLayout(msg, icon),
        showCancelButton: true,
        confirmButtonText: __t("Si"),
        cancelButtonText: __t("No"),
    }).then(function (result) {
        if (result.value) {
            return callback();
        }
    });
}

export function sa2Alert(msg, icon = "warning") {
    swalWithBootstrapButtons.fire({
        html: createLayout(msg, icon),
        customClass: {
            confirmButton: 'btn btn-primary btn-sm btn-block'
        },
        buttonsStyling: false
    });
}

export function sa2Toast(msg, icon = "info") {
    swalWithBootstrapButtons.fire({
        html: createLayout(msg, icon),
        position: "center",
        customContainerClass: 'swal2-absolute web-toast',
        target: document.getElementById("main"), //TODO
        showConfirmButton: false,
        backdrop: false,
        allowOutsideClick: false,
        timer: 3000
    });
}
