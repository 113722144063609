import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

//Car
export default function solutions(content, nextState, vrs) { // content div della view
    const {context, requestData, assetType} = vrs.session;

    var modal = $(content).closest('.modal');
    var errorMsgEl = $(".user-msg", content).hide();//Cross views
    var form = $(".view-form form", modal);// form ricerca

    var currentHtml = nextState.html;

    $(".modal-body .solutions-content", content).empty().html(nextState.html).scrollTop(0);// contenuto dinamico    

    // Ricerca in depositi vicini
    $(content).on('click', '.user-search-near', function (e) {
        e.preventDefault();

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var xhr = $.post(task("Booking.searchCar"), Utils.serializeForm(form, {nearbyCriteria: "1", searchType: "space"}), function (data) {

            if (!data.success) {// Errore durante la ricerca
                $(errorMsgEl).text(data.message).show();
                return;
            }

            // Ricarica la stessa vista
            currentHtml = data.html;
            vrs.goToView("solutions", {
                html: data.html
            }, true);

        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore durante la ricerca"), 'error', status);
            }
        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    // Conferma soluzione
    $(content).on('submit', ".user-confirm-solution", function (e) {
        e.preventDefault();

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var sendData = {
            rawSolution: $("[name=rawSolution]", this).val(),
            bookingId: $("[name=bookingId]", this).val(),
            promotionId: $("[name=promotionId]", this).val(),
            assetType: assetType,
        };

        var xhr = $.post(task("Booking.register"), sendData, function (data) {

            if (!data.success) {
                sa2Alert(data.message, "warning");
                return;
            }

            if (data.message.trim()) {
                sa2Alert(data.message, "warning");
            }

            $(modal).modal('hide');

        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore durante la conferma della prenotazione"), 'error', status);
            }

        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    //Utente accetta soluzione pooling
    $(content).on('click', ".user-chooses-shared-solution", function (e) {
        e.preventDefault();
        var id = parseInt($(this).data("id"));
        var seatsCount = $(this).data("seats");
        var owner = $(this).data("owner");

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var postData = {"seatsCount": seatsCount, "bookingId": id, assetType: "CAR"};
        var xhr = $.post(task("Booking.poolingConfirm"), postData, function (data) {
            if (!data.success) {
                sa2Alert(data.message, "warning");
            } else {
                //showForm();
                //$(modal).modal('hide');
                vrs.goToView("polling-thx", {
                    owner: owner,
                });
            }
        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore di comunicazione con il server"), 'error', status);
            }
        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    //Utente scarta soluzione pooling
    $(content).on('click', ".user-discard-pooling-search", function (e) {
        e.preventDefault();

        var discardIds = [];
        $(".user-chooses-shared-solution", content).each(function () {
            discardIds.push(parseInt($(this).data("id")));
        });

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var postData = {"bookingIds": discardIds, assetType: assetType};

        var xhr = $.post(task("Booking.poolingReject"), postData, function (data) {
            if (!data.success) {
                $(errorMsgEl).text(data.message).show();
                return;
            } else {
                $(".result-pooling", content).hide();
                $(".result-classic", content).fadeIn();
            }
        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore di comunicazione con il server"), 'error', status);
            }
        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    //Ricerca calendario avviabilità
    $(content).on('click', '.user-search-aviability', function (e) {
        e.preventDefault();

        var criteria = JSON.parse($("#input-criteria", content).val());// Richiesta iniziale ricevuta via html
        var bookingId = $("#input-booking-id", content).val();

        if (!(criteria && criteria.tripFeatures && criteria.tripFeatures.departureParkingAreaId)) {
            console.log("Missing criteria");
            return;
        }

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var request = {
            criteria: JSON.stringify(criteria),
            bookingId: bookingId,
            assetType: assetType,
        };

        var xhr = $.post(task("Booking.getDepotAviability"), request, function (data) {
            if (!data.success) {
                sa2Alert(data.message, "warning");
                return;
            } else {
                vrs.goToView("aviability", {
                    assetAvailability: data.assetAvailability,
                    departure: data.departure,
                    criteria: criteria,
                });
                //console.log(data);
            }
        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore di comunicazione con il server"), 'error', status);
            }
        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);

    });

    $(".estimate-cost", content).each(function (index) {
        var element = this;
        $(element).html('<div class="spinner-grow spinner-grow-sm text-primary"></div>');

        var jXhrQ = $.post(task("Booking.estimateSolution"), {solution: $(element).data("solution"), assetPath: "car"}, function (data) {

            if (!data.success) {// Errore durante la ricerca
                $(element).html('<i class="fas fa-exclamation-triangle"></i>');
            } else {
                $(element).html(data.message);
            }

        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            $(element).html('<i class="fas fa-exclamation-triangle"></i>');
        });

        vrs.registerXhrs(jXhrQ);
    });

    //Valuta promozione
    $(content).on('click', '.estimate-promotions', function (e) {
        var element = this;
        var solution = $(element).closest(".solution-item-container").find("input[name=rawSolution]").val();
        var index = $(element).data("index");// Indice

        context.setLoading(true);
        var jXhrQ = $.post(task("Booking.estimatePromotions"), {solution: solution, index: index, assetPath: "car"}, function (data) {

            context.setLoading(false);

            if (!data.success) {// Errore durante la ricerca
                sa2Alert(data.message, "warning");
            } else {
                vrs.goToView("promotions", {
                    html: data.html,
                    solutionsHtml: currentHtml//Rimbalza html :(
                });
            }

        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            //todo;
        });
        vrs.registerXhrs(jXhrQ);
    });

    if (nextState.hasOwnProperty("promotion")) {
        var promotion = nextState.promotion;
        var promotionItem = $(".solution-item-" + promotion.index, content);
        $(".promotions-request-btn", promotionItem).hide();
        $(".promotions-result-txt", promotionItem).show().text(promotion.title);
        $(".user-confirm-solution [name=promotionId]", promotionItem).val(promotion.promotionId);
    }


    return function () {
    }
};
