import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

export default function aviabilityForm(content, nextState, vrs) { // content div della view
    const {context, requestData, assetType} = vrs.session;
    const {departure, instance, criteria} = nextState;

    //console.log(requestData);
    var bookingId = null;
    if (requestData.hasOwnProperty("bookingId")) {
        bookingId = requestData.bookingId;
    }

    var form = $("form", content);
    var titleEl = $(".view-title", content);
    var modal = $(content).closest('.modal');
    var errorMsgEl = $(".user-msg", modal).hide();//Cross views

    $(titleEl).html(Utils.describeAviabilityFromTo(departure, instance, criteria));

    function redDate(group) {
        var date = $(".date", group).val();
        var hours = $(".hours", group).val();
        var minutes = $(".minutes", group).val();

        if (!date || !hours || !minutes) {
            return false;
        }

        var dateObj = moment(date + " " + hours + ":" + minutes, "L HH:mm");
        return dateObj.isValid() ? dateObj.seconds(0).milliseconds(0) : false;
    }

    function setDate(group, date) {
        $(".date", group).val(date.format("L"));
        $(".hours", group).val(date.format("HH"));
        $(".minutes", group).val(date.format("mm"));
    }

    function isValidDate(startDate, endDate) {
        if (!startDate || !startDate.isValid()) {
            sa2Alert(__t("Data di inizio non valida"));
            return false;
        }
        if (!endDate || !endDate.isValid()) {
            sa2Alert(__t("Data di fine non valida"));
            return false;
        }

        var aviabilityStartDate = moment(instance.startDate).seconds(0).milliseconds(0);
        var aviabilityEndDate = moment(instance.endDate).seconds(0).milliseconds(0);

        if (startDate >= endDate) {
            sa2Alert(__t("La data di partenza deve essere maggiore di quella di arrivo"));
            return false;
        }

        if (startDate < aviabilityStartDate || endDate > aviabilityEndDate) {
            sa2Alert(__t("L'intervallo selezionato non ricade nelle disponibilità"));
            return false;
        }

        return true;
    }

    var startDate = moment(instance.startDate);
    var endDate = moment(instance.endDate);

    var requestStartDate = startDate.clone();
    var requestEndDate = endDate.clone();

    // Aggiusto le date dell'istanza in base alla richiesta iniziale (se ha la targa la data di inizio può essere fissa nel passato)
    if (criteria && criteria.hasOwnProperty("startDate") && criteria.hasOwnProperty("endDate")) {
        var criteriaStartDate = moment(criteria.startDate);
        var criteriaEndDate = moment(criteria.endDate);

        if (criteriaStartDate.isValid() && criteriaStartDate.isAfter(startDate) && criteriaStartDate.isBefore(endDate)) {
            requestStartDate = criteriaStartDate;
        }

        if (criteriaEndDate.isValid() && criteriaEndDate.isAfter(requestStartDate) && criteriaEndDate.isBefore(endDate)) {
            requestEndDate = criteriaEndDate;
        }
    }

    $(".group-start-date .date").datepicker("destroy").datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: "L",
        minDate: startDate.toDate(),
    });
    setDate($(".group-start-date"), requestStartDate);

    $(".group-end-date .date").datepicker("destroy").datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: "L",
        maxDate: endDate.toDate(),
    });
    setDate($(".group-end-date"), requestEndDate);

    $(form).off().on("submit", function (event) {
        event.preventDefault();

        var startDate = redDate($(".group-start-date"));
        var endDate = redDate($(".group-end-date"));

        if (!isValidDate(startDate, endDate)) {
            return;
        }

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var request = $.extend({}, instance);// Clone
        delete request["assetData"];//Remove extra data
        request.startDate = startDate.format();
        request.endDate = endDate.format();

        var xhr = $.post(task("Booking.registerFromAviability"), {criteria: JSON.stringify(request), bookingId: bookingId}, (response) => {

            if (response.success) {
                $(modal).modal('hide');
            } else {
                sa2Alert(response.message, "warning");
            }

        }, 'json').done(function () {
        }).fail(function (jqxhr, textStatus, error) {
        }).always(() => {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    $(".btn-estimate-availability", content).toggle((criteria && criteria.hasOwnProperty("tripFeatures") && criteria.tripFeatures.tripMode === "PRIVATE"));

    $(content).on("click", ".btn-estimate-availability", function (e) {
        e.preventDefault();

        var startDate = redDate($(".group-start-date"));
        var endDate = redDate($(".group-end-date"));

        if (!isValidDate(startDate, endDate)) {
            return;
        }

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var request = $.extend({}, instance);// Clone
        delete request["assetData"];//Remove extra data
        request.startDate = startDate.format();
        request.endDate = endDate.format();
        request.assetId = instance.assetId;

        var xhr = $.post(task("Booking.estimateAvailability"), {solution: JSON.stringify(request), assetPath: "car"}, (response) => {

            var messageType = response.success ? "info" : "warning";
            sa2Alert(response.message, messageType);

        }, 'json').done(function () {
        }).fail(function (jqxhr, textStatus, error) {
        }).always(() => {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    return function () {
        $(titleEl).empty();
        $(".date").datepicker("destroy");
    };
};
