import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

export default function aviability(content, nextState, vrs) { // content div della view
    const {context, requestData, assetType} = vrs.session;
    const {departure, assetAvailability, criteria} = nextState;
    //console.log(nextState);

    var titleEl = $(".view-title", content);
    var listEl = $(".list-items", content);

    $(titleEl).html(Utils.describeAviabilityFromTo(departure));

    $.each(nextState.assetAvailability, function (index, instance) {
        var startDateInfo = moment(instance.startDate).format("L HH:mm");
        var endDateInfo = moment(instance.endDate).format("L HH:mm");

        var itemAsset = (instance && instance.assetData) ? Utils.escapeHtml(instance.assetData.model) + " (" + instance.assetData.plate + ")" : "";
        var itemAviability = __t("Disponibile dal %s al %s", null, startDateInfo, endDateInfo);
        var itemCategory = (instance && instance.categoryData) ? Utils.escapeHtml(instance.categoryData.description) : "";
        var itemSeatsCount = (instance && instance.assetData) ? Utils.escapeHtml(instance.assetData.seatsCount) : "";
        var itemFuelSupply = (instance && instance.assetData) ? instance.assetData.fuelSupply : "";

        switch (itemFuelSupply) {
            case "ELECTRICITY":
                itemFuelSupply = __t("Elettrica");
                break;
            case "GASOLINE":
                itemFuelSupply = __t("Benzina");
                break;
            case "DIESEL":
                itemFuelSupply = __t("Diesel");
                break;
        }

        itemFuelSupply = Utils.escapeHtml(itemFuelSupply)

        var element = "";

        if (criteria && criteria.hasOwnProperty("tripFeatures") && criteria.tripFeatures.tripMode === "PRIVATE") {

            element = `
                    <div class="card mb-2 shadow-sm" data-id=${index}>
                       <div class="card-body p-2" role="button">
                        <div class="row align-items-center">
                            <div class="col">
                                <h5 class='mb-1 text-primary'>${itemAsset}</h5>
                                <div class='small'>${itemAviability}</div>
                                <div class='row small'>
                                    <div class='col-md my-1'><i class="fas fa-car text-primary"></i> ${__t("Tipologia")}: ${itemCategory}</div>
                                    <div class='col-md my-1'><i class="fas fa-gas-pump text-primary"></i> ${__t("Alimentazione")}: ${itemFuelSupply}</div>
                                    <div class='col-md my-1'><i class="fas fa-users text-primary"></i> ${__t("Posti")}: ${itemSeatsCount}</div>    
                                </div>
                            </div> 
                            <div class="col-auto">
                                <i class="fas fa-chevron-right"></i>
                            </div> 
                        </div>                            
                       </div>     
                    </div>               
            `;
        } else {

            element = `
                    <div class="card mb-2 shadow-sm" data-id=${index}>
                       <div class="card-body p-2" role="button">
                        <div class="row align-items-center">
                            <div class="col">
                                <h5 class='mb-1 text-primary'>${itemCategory}</h5>
                                <div class='small'>${itemAviability}</div>
                            </div> 
                            <div class="col-auto">
                                <i class="fas fa-chevron-right"></i>
                            </div> 
                        </div>                            
                       </div>     
                    </div>               
            `;
        }

        $(listEl).append(element);
    });

    $(content).on('click', '.list-items > .card', function (e) {
        e.preventDefault();
        var selectedIndex = $(this).data("id");
        var selectedInstance = assetAvailability[selectedIndex];

        if (selectedInstance) {
            vrs.goToView("aviability-form", {
                departure: departure,
                instance: selectedInstance,
                criteria: criteria
            });
        }
    });

    return function () {
        $(titleEl).empty();
        $(listEl).empty();
    };
};
