import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

//Bike
export default function solutions(content, nextState, vrs) { // content div della view
    const {context, requestData, assetType} = vrs.session;

    var modal = $(content).closest('.modal');
    var errorMsgEl = $(".user-msg", content).hide();
    var form = $(".view-form form", modal);// form ricerca

    $(".modal-body .solutions-content", content).empty().html(nextState.html).scrollTop(0);// contenuto dinamico

    // Ricerca in depositi vicini
    $(content).on('click', '.user-search-near', function (e) {
        e.preventDefault();

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var xhr = $.post(task("Booking.searchBike"), Utils.serializeForm(form, {nearbyCriteria: "1", searchType: "space"}), function (data) {

            if (!data.success) {// Errore durante la ricerca
                $(errorMsgEl).text(data.message).show();
                return;
            }

            // Ricarica la stessa vista
            vrs.goToView("solutions", {
                html: data.html
            }, true);

        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore durante la ricerca"), 'error', status);
            }
        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    // Conferma soluzione
    $(content).on('submit', ".user-confirm-solution", function (e) {
        e.preventDefault();

        $(errorMsgEl).hide();
        vrs.clearXhrs();
        context.setLoading(true);

        var sendData = {
            rawSolution: $("[name=rawSolution]", this).val(),
            bookingId: $("[name=bookingId]", this).val(),
            promotionId: $("[name=promotionId]", this).val(),
            assetType: assetType,
        };

        var xhr = $.post(task("Booking.register"), sendData, function (data) {

            if (!data.success) {
                sa2Alert(data.message, "warning");
                return;
            }

            if (data.message.trim()) {
                sa2Alert(data.message, "warning");
            }

            $(modal).modal('hide');

        }, 'json').done(function () {
        }).fail(function (xhr, status, error) {
            if (status !== "abort") {
                sa2Toast(__t("Errore durante la conferma della prenotazione"), 'error', status);
            }

        }).always(function () {
            context.setLoading(false);
        });

        vrs.registerXhrs(xhr);
    });

    return function () {
       
    }
};
