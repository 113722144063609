import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "../../../Utils/Utils";

export default function pollingThx(content, nextState, vrs) { // content div della view
    const {context, requestData, assetType} = vrs.session;
    var owner = nextState.owner;
    var textInfo = $(".text-owner-info", content);

    var phHtml = $(textInfo).next();
    $(".data-fullname", phHtml).text(Utils.escapeHtml(owner.name + " " + owner.surname));
    $(".data-telephone", phHtml).text(owner.telephone);

    $(textInfo).html($(phHtml).html());

    return function () {
    }

}
