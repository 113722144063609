import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";

import ViewsRouteSwitcher from "../../Utils/ViewsRouteSwitcher";
import Utils from "../../Utils/Utils";

import form from "./Handlers/form";
import thx from "./Handlers/thx";

export default function shuttleRideEntry(container, context, response, requestData, fnStatus) {
    //container= $(".rv-content", modal)

    var router = new ViewsRouteSwitcher($(".views", container), {
        context: context,
        requestData: requestData,
        assetType: "SHUTTLERIDE",
        fnStatus: fnStatus,
        response: response,
    });

    router.registerViewHandler(form, "form");
    router.registerViewHandler(thx, "success");

    router.goToView("form");

    return function () {
        router.destroy();
    };

};

