import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";

import ViewsRouteSwitcher from "../../Utils/ViewsRouteSwitcher";
import Utils from "../../Utils/Utils";

import form from "./Handlers/form";
import solutions from "./Handlers/solutions";
import pollingThx from "./Handlers/pollingThx";
import addDestination from "./Handlers/addDestination";
import promotions from "./Handlers/promotions";
import aviability from "./Handlers/aviability";
import aviabilityForm from "./Handlers/aviabilityForm";

//context RemoteView
//container .rv-content
export default function carEntry(container, context, response, requestData, fnStatus) {
    //container= $(".rv-content", modal)
    //console.log(requestData, response);

    var router = new ViewsRouteSwitcher($(".views", container), {
        context: context,
        requestData: requestData, // Richiesta iniziale di apertura modale ( puo contenere bookingId)
        assetType: "CAR",
        fnStatus: fnStatus,
        response: response, // Contenuto html incollato nel modale, diviso per viste
    });

    router.registerViewHandler(form, "form");
    router.registerViewHandler(solutions, "solutions");
    router.registerViewHandler(pollingThx, "polling-thx");
    router.registerViewHandler(addDestination, "add-destination");
    router.registerViewHandler(promotions, "promotions");
    router.registerViewHandler(aviability, "aviability");
    router.registerViewHandler(aviabilityForm, "aviability-form");
    //router.registerViewHandler(defaultView,"default");

    router.goToView("form");

    return function () {
        router.destroy();
    };

};

