
class ViewsRouteSwitcher {

    constructor(container, initSession = {}, autoHide = false) {
        this.container = $(container);
        this.session = initSession;
        this.handlers = new Map();
        this.currentDestroy = null;
        this.xhrs = new Set();
        this.history = [];
    }

    goToView = (viewId, nexState = null, forgot = false) => {
        if (!this.container) {
            console.log("Container unaviable");
            return;
        }

        var viewEl = $(".view-" + viewId, this.container);

        if (!$(viewEl).length) {
            console.log("View " + viewId + " not found");
            return;
        }
        ;

        this.clearXhrs();
        this.clearCurrentDestroy();
        this.offTargetContainers();

        $(".view-item", this.container).hide().css('pointer-events', 'none');
        $(viewEl).show().css('pointer-events', '');

        if (this.handlers.has(viewId)) {
            this.currentDestroy = (this.handlers.get(viewId))(viewEl, nexState, this);
        } else {
            console.log("Missing view handler " + viewId);
        }
        ;

        //Register common back
        $(viewEl).on('click', '.go-back', (e) => {
            e.preventDefault();
            this.goBack();
        });

        $(viewEl).on('click', '.go-back-to', (e) => {
            e.preventDefault();
            this.goToView($(e.target).data("view"), {});
        });

        if (!forgot) {
            this.history.push({
                viewId: viewId,
                nexState: nexState
            });
        }
        ;

    }

    goBack = () => {
        this.history.pop();//Remove current
        var popped = this.history.pop();// Get Prev
        if (popped) {
            this.goToView(popped.viewId, popped.nexState);
        } else {
            console.log("empty history");
        }
    }

    registerViewHandler = (callback, viewId) => {
        this.handlers.set(viewId, callback);
        return this;
    }

    registerXhrs = (xhr) => {
        this.xhrs.add(xhr);
        return this;
    }

    clearXhrs = () => {
        this.xhrs.forEach(function (value) {
            value.abort();
        });
        this.xhrs.clear();
        return this;
    }

    clearCurrentDestroy = () => {
        try {
            if (this.currentDestroy && typeof this.currentDestroy === "function") {
                this.currentDestroy();
            }
            this.currentDestroy = null;
        } catch (e) {
            console.log("Fail currentDestroy", e);
        }
        return this;
    }

    offTargetContainers = () => {
        $(".view-item", this.container).each(function (index) {
            $(this).off();//Remove all events
        });
    }

    destroy = () => {
        this.clearXhrs();
        this.clearCurrentDestroy();
        this.offTargetContainers();
        this.container = null;
        this.handlers.clear();
        this.history = [];
    }
}

export default ViewsRouteSwitcher;

