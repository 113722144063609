/*
 * This file is part of the CloserPHP package.
 * For the full copyright and license information, please view the LICENSE.php
 * file that was distributed with this source code.
 * Info:andread.dev@gmail.com
 */

export default function useFormUtils(form, options = {}) {
    options = $.extend({}, {messageWraper: ".form-message-wraper"}, options);
    form = $(form);

    function flatObject(input) {
        function flat(res, key, val, pre = '') {
            const prefix = [pre, key].filter(v => v).join('.');
            return typeof val === 'object'
                    ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
                    : Object.assign(res, {[prefix]: val});
        }

        return Object.keys(input).reduce((prev, curr) => flat(prev, curr, input[curr]), {});
    }

    return{
        field: function (name) {
            return $('[name=' + name + ']', form);
        },
        showMessage(message, type = "warning") {
            var content = `<div class="alert alert-${type} alert-dismissible fade show mb-2" role="alert">
            ${message}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>`;

            $(options.messageWraper, form).empty().html(content);

        },
        hideMessage() {
            $(options.messageWraper, form).empty();
        },
        showErrors(errorFields) {

            errorFields = flatObject(errorFields);

            $.each(errorFields, function (nameId, v) {

                nameId = nameId.replace(/\./g, '\\.');// Escape dot separator

                var emId = ".cp-msg-" + nameId;
                var lbId = ".cp-lbl-" + nameId;
                var flId = ".cp-fld-" + nameId;

                if ($(emId, form).length) {
                    $(emId, form).html(v).removeClass('d-none').addClass("d-block");
                } else {
                    console.log("Error field " + nameId + " not found");
                }

                if ($(lbId, form).length) {
                    $(lbId, form).addClass("invalid-lbl-feedback");
                }

                if ($(flId, form).length) {
                    $(flId, form).addClass("is-invalid");
                }
            });
        },
        clearErrors() {
            $(".invalid-feedback", form).removeClass('d-block').addClass("d-none");
            $(".invalid-lbl-feedback", form).removeClass("invalid-lbl-feedback");
            $(".is-invalid", form).removeClass("is-invalid");
        },
        clearErrorOnFocus() {
            $("input,select,textarea", form).not(":submit").focus(function () {

                if (!$(this).attr("name")) {
                    return;
                }

                var nameId = $(this).attr('name');
                nameId = nameId.replace("][", ".");
                nameId = nameId.replace("[", ".");
                nameId = nameId.replace("]", ".");
                nameId = nameId.replace(/^\.+|\.+$/g, ''); // Trim dot
                nameId = nameId.replace(/\./g, '\\.');// Escape dot separator

                var emId = ".cp-msg-" + nameId;
                var lbId = ".cp-lbl-" + nameId;
                var flId = ".cp-fld-" + nameId;

                if ($(emId, form).length) {
                    $(emId, form).removeClass('d-block').addClass("d-none");
                }

                if ($(lbId, form).length) {
                    $(lbId, form).removeClass("is-invalid");
                }

                if ($(flId, form).length) {
                    $(flId, form).removeClass("is-invalid");
                }

            });
        },
        reset() {
            $(form)[0].reset();
        },
    };
}


